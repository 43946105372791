<template>
  <div class="ticket-buyer">
    <div id="Content">
      <div class="shadow"></div>
      <div class="content-inner">
        <div class="content-path">
          <breadcrumbs :crumbs="breadcrumbs" />
        </div>
        <div class="headers">
          <span>
            Name:
            <span class="inner-content">{{ eventdata.name }}</span>
          </span>
          <br />
          <span>
            Venue Name:
            <span class="inner-content">{{ eventdata.venue }}</span>
          </span>
          <br />
          <span>
            Event Start Date:
            <span class="inner-content">{{ eventdata.start }}</span>
          </span>
          <br />
        </div>
        <section>
          <div class="row">
            <div class="col full">
              <DataTable
                title="Buyer's List"
                endpoint="/promoters/event-tickets-list/:event"
                :args="{ event: event }"
                :params="{ event: event }"
                ref="table"
                :csvMapper="ticketsCSVMapper"
                :headers="this.tableContent.columns"
                searchPlaceholder="Name or Email Search"
              >
                <!-- <template v-slot:[`item.notes`]="{ item }">
              <v-icon @click="notes(item)">mdi-pencil</v-icon>
            </template>
            <template v-slot:[`item.details`]="{ item }">
              <v-icon @click="details(item)">mdi-information-outline</v-icon>
            </template> -->
              </DataTable>
            </div>
          </div>
          <div class="row">
            <div class="col full">
              <span style="display:none;">hidden</span>
              <DataTable
                title="Event Registrations"
                subtitle="NOTE: These are not purchases. For events with purchases, these are people who entered their email but did not complete a purchase."
                endpoint="/promoters/event-registration-list/:event"
                :args="{ event: event }"
                :headers="this.eventRegistrationTable.columns"
                :total-count="true"
              ></DataTable>
            </div>
          </div>
        </section>
      </div>
    </div>
  </div>
</template>

<style lang="less" scoped>
.ticket-buyer {
  #Content {
    .content-inner {
      .button-container {
        display: flex;
        flex-wrap: wrap;
        width: 100%;
        justify-content: center;
      }

      .buyer-wrapper {
        display: flex;
        justify-content: flex-end;
        margin: 0px 0px 10px 0px;
      }

      .headers {
        left: 10px;
        color: var(--primary-orange);
        font-weight: 700;
        font-size: larger;
        .inner-content {
          color: rgba(252, 245, 245, 0.89);
          font-weight: 200;
          font-size: large;
        }
      }
      .button-wrapper {
        position: relative;
        bottom: -10px; // bottom: -89px;
        .diyobo-button {
          padding: 8px 33px 6px 11px;
          //border-radius: 10px;
        }
        .print-icon {
          position: absolute;
          top: 15px;
          left: 117px;
          z-index: 1;
          cursor: pointer;
        }
      }

      ::-webkit-scrollbar {
        width: 0px; /* Remove scrollbar space */
        background: transparent; /* Optional: just make scrollbar invisible */
      }
      /* Optional: show position indicator in red */
      ::-webkit-scrollbar-thumb {
        background: transparent;
      }
    }
    // end content-inner
  }
  // end of content
}
</style>
<script>
import DiyoboInput from "@/components/DiyoboInput.vue";
import DiyoboButton from "@/components/DiyoboButton.vue";
import { library } from "@fortawesome/fontawesome-svg-core";
import { faPrint, faFilter } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/vue-fontawesome";
import TableTote from "@/components/TableTote.vue";
import Breadcrumbs from "@/components/Breadcrumbs.vue";
import dateFormat from "dateformat";
import DataTable from "@/components/DataTable.vue";

library.add(faPrint);

export default {
  name: "ticket-buyer",
  components: {
    DiyoboInput,
    DiyoboButton,
    TableTote,
    Breadcrumbs,
    FontAwesomeIcon,
    DataTable
  },
  props: {
    event: String
  },
  head() {
    return {
      title: `${this.eventdata.name}'s Tickets`
    };
  },
  data() {
    return {
      breadcrumbs: [
        ["Dashboard", "/dashboard"],
        ["Event Buyers List", null]
      ],
      eventdata: {},
      ticketTotals: [],
      tableData: {},
      tableContent: {
        title: "Event Ticket List",
        columns: [
          { text: "Ticket Tier", value: "tier", width: "10%" },
          { text: "Ticket #", value: "number", width: "6%" },
          { text: "Refunded", value: "refunded", width: "6%" },
          // { text: "Validator", value: "validator", hidden: true },
          // { text: "Purchase Date", value: "date", width: "14%", date: true },
          { text: "Purchaser", value: "purchaser", width: "10%" },
          { text: "Purchaser Email", value: "email", width: "20%" },
          // { text: "Attendee Name", value: "attendee_name", width: "15%" },
          // { text: "Attendee Email", value: "attendee_email", width: "20%" },

          // { text: "City", value: "city", hidden: true },
          // { text: "Age", value: "age", hidden: true },
          // { text: "Email", value: "email", hidden: true },
          // { text: "Phone Number", value: "phone", hidden: true },
          { text: "Language", value: "preferred_language", width: "10%" },
          // {
          //   text: "Ticket $(-Fee)",
          //   value: "revenue",
          //   isCurrency: true,
          //   hidden: true
          // },
          // { text: "Cash", value: "cash", hidden: true },
          // { text: "Promo Code", value: "promo", hidden: true },
          // { text: "Sold By", value: "sold_by", hidden: true },
          { text: "Type", value: "type", width: "8%" }
        ],
        tab: 0,
        tabs: ["ORDER DETAILS", "TICKET DETAILS", "ADDITIONAL DETAILS"]
      },
      eventRegistrationTable: {
        columns: [
          { text: "First Name", value: "first_name", width: "20%" },
          { text: "Last Name", value: "last_name", width: "20%" },
          { text: "Email", value: "email", align: "20%" },
          { text: "Promoter", value: "promoter_name", align: "20%" },
          { text: "Created", value: "created_at", align: "20%" }
        ]
      }
    };
  },
  methods: {
    ticketsCSVMapper(tickets) {
      let ticketList = [];

      tickets.forEach(ticket => {
        let list = {
          "Ticket Tier": ticket.tier,
          "Ticket #": ticket.number,
          Refunded: ticket.refunded,

          Validator: ticket.validator,
          "Purchase Date": ticket.date,
          Purchaser: ticket.purchaser,
          "Purchaser Email": ticket.email,
          "Attendee Name": ticket.attendee_email,
          "Attendee Email": ticket.attendee_name,

          City: ticket.city,
          Age: ticket.age,
          "Ticket $(-Fee)": ticket.revenue,

          Language: ticket.preferred_language,
          Company: ticket.associated_company || "Other",
          Cash: ticket.cash,
          "Promo Code": ticket.promo,
          "Sold By": ticket.sold_by,
          Type: ticket.type
        };

        ticketList.push(list);
      });

      return ticketList;
    }
  },
  created() {
    this.$store.commit("setTitle", "Event Buyers List");

    this.$loader.start();
    this.$axios
      .post("/promoters/event-tickets-list", {
        event: this.event
      })
      .then(response => {
        this.eventdata = response.data.event;
        this.eventdata.start = dateFormat(
          +this.eventdata.start.$date.$numberLong,
          "ddd, mmm dS, yyyy"
        );

        this.$store.commit("setSubtitle", this.eventdata.name);

        // const tickets = (this.ticketTotals = response.data.tickets.map(t => {
        //   t.date = dateFormat(
        //     t.date.$date ? +t.date.$date.$numberLong : t.date,
        //     "ddd, mmm dS, yyyy"
        //   );
        //   return t;
        // }));

        // this.tableData = {
        //   title: "Event Tickets Quick View",
        //   columns: [
        //     { title: "Ticket Tier", field: "tier", width: "10%" },
        //     { title: "Ticket #", field: "number", width: "6%" },
        //     { title: "Refunded", field: "refunded", width: "6%" },
        //     { title: "Validator", field: "validator", hidden: true },
        //     { title: "Purchase Date", field: "date", width: "14%", date: true },
        //     { title: "Purchaser Email", field: "email", width: "20%" },
        //     // { title: "Attendee Name", field: "attendee_name", width: "15%" },
        //     { title: "Attendee Email", field: "attendee_email", width: "20%" },
        //     { title: "Purchaser", field: "purchaser", width: "10%" },
        //     { title: "City", field: "city", hidden: true },
        //     { title: "Age", field: "age", hidden: true },
        //     { title: "Email", field: "email", hidden: true },
        //     // { title: "Phone Number", field: "phone", hidden: true },
        //     { title: "Language", field: "preferred_language", width: "10%" },
        //     {
        //       title: "Ticket $(-Fee)",
        //       field: "revenue",
        //       isCurrency: true,
        //       hidden: true
        //     },
        //     { title: "Cash", field: "cash", hidden: true },
        //     { title: "Promo Code", field: "promo", hidden: true },
        //     { title: "Sold By", field: "sold_by", hidden: true },
        //     { title: "Type", field: "type", width: "8%" }
        //   ],
        //   data: tickets
        // };
      })
      .finally(() => {
        this.$loader.stop();
      });
  }
};
</script>
